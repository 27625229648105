import { j as t } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as p } from "react";
import { I as a } from "./index-CAn76F0e.js";
import "./Accordion-B6McINfk.js";
import "./Avatar-C7XsTcvJ.js";
import "./Badge-C0bj4DFU.js";
import "./Button-BrPm3sL-.js";
import "./DropdownMenu-DcXXsN6j.js";
import "./Icon-C5a5ARPP.js";
import { I as i } from "./Input-vl7Xx2ol.js";
import "./Label-Bzu7sDuW.js";
import "./Text-Bn-rHqca.js";
import "./Tooltip-DfaQUwkm.js";
import "./Stack-DHVjAP0n.js";
import "./Switch-Dlb1qEPd.js";
import "./Toast-C4Hef5AO.js";
import "./Toggle-BBLW8KXH.js";
const e = p(
  (m, o) => /* @__PURE__ */ t.jsx(a, { ...m, inputRef: o, mask: "999-999-9999", maskChar: "_", children: (r) => /* @__PURE__ */ t.jsx(
    i,
    {
      wrapClassName: r.wrapClassName,
      ...r,
      disabled: m.disabled,
      type: "tel",
      placeholder: "000-000-0000"
    }
  ) })
);
e.displayName = "PhoneNumberInput";
export {
  e as default
};
