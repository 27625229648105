import { _ as C, a as z } from "./index-28E3tTLh.js";
import { forwardRef as A, createElement as b, useRef as G, useState as y, useEffect as O, useCallback as v } from "react";
import { a as H, $ as j, b as $ } from "./index-B5chcmfZ.js";
import { $ as q } from "./index-egs6Xnuq.js";
import { $ as J } from "./index-Cp-Nu9_j.js";
import { $ as Q } from "./index-rKUeoTl7.js";
import { $ as N } from "./index-CZBInFmv.js";
import { $ as W } from "./index-U5S6G_il.js";
const _ = "rovingFocusGroup.onEntryFocus", X = {
  bubbles: !1,
  cancelable: !0
}, h = "RovingFocusGroup", [S, M, Z] = q(h), [ee, ge] = J(h, [
  Z
]), [oe, te] = ee(h), re = /* @__PURE__ */ A((e, o) => /* @__PURE__ */ b(S.Provider, {
  scope: e.__scopeRovingFocusGroup
}, /* @__PURE__ */ b(S.Slot, {
  scope: e.__scopeRovingFocusGroup
}, /* @__PURE__ */ b(ne, C({}, e, {
  ref: o
}))))), ne = /* @__PURE__ */ A((e, o) => {
  const { __scopeRovingFocusGroup: n, orientation: t, loop: I = !1, dir: F, currentTabStopId: g, defaultCurrentTabStopId: T, onCurrentTabStopIdChange: i, onEntryFocus: f, ...E } = e, l = G(null), p = z(o, l), m = W(F), [c = null, d] = H({
    prop: g,
    defaultProp: T,
    onChange: i
  }), [x, s] = y(!1), a = j(f), U = M(n), R = G(!1), [K, D] = y(0);
  return O(() => {
    const r = l.current;
    if (r)
      return r.addEventListener(_, a), () => r.removeEventListener(_, a);
  }, [
    a
  ]), /* @__PURE__ */ b(oe, {
    scope: n,
    orientation: t,
    dir: m,
    loop: I,
    currentTabStopId: c,
    onItemFocus: v(
      (r) => d(r),
      [
        d
      ]
    ),
    onItemShiftTab: v(
      () => s(!0),
      []
    ),
    onFocusableItemAdd: v(
      () => D(
        (r) => r + 1
      ),
      []
    ),
    onFocusableItemRemove: v(
      () => D(
        (r) => r - 1
      ),
      []
    )
  }, /* @__PURE__ */ b(N.div, C({
    tabIndex: x || K === 0 ? -1 : 0,
    "data-orientation": t
  }, E, {
    ref: p,
    style: {
      outline: "none",
      ...e.style
    },
    onMouseDown: $(e.onMouseDown, () => {
      R.current = !0;
    }),
    onFocus: $(e.onFocus, (r) => {
      const B = !R.current;
      if (r.target === r.currentTarget && B && !x) {
        const P = new CustomEvent(_, X);
        if (r.currentTarget.dispatchEvent(P), !P.defaultPrevented) {
          const w = U().filter(
            (u) => u.focusable
          ), k = w.find(
            (u) => u.active
          ), Y = w.find(
            (u) => u.id === c
          ), V = [
            k,
            Y,
            ...w
          ].filter(Boolean).map(
            (u) => u.ref.current
          );
          L(V);
        }
      }
      R.current = !1;
    }),
    onBlur: $(
      e.onBlur,
      () => s(!1)
    )
  })));
}), ce = "RovingFocusGroupItem", fe = /* @__PURE__ */ A((e, o) => {
  const { __scopeRovingFocusGroup: n, focusable: t = !0, active: I = !1, tabStopId: F, ...g } = e, T = Q(), i = F || T, f = te(ce, n), E = f.currentTabStopId === i, l = M(n), { onFocusableItemAdd: p, onFocusableItemRemove: m } = f;
  return O(() => {
    if (t)
      return p(), () => m();
  }, [
    t,
    p,
    m
  ]), /* @__PURE__ */ b(S.ItemSlot, {
    scope: n,
    id: i,
    focusable: t,
    active: I
  }, /* @__PURE__ */ b(N.span, C({
    tabIndex: E ? 0 : -1,
    "data-orientation": f.orientation
  }, g, {
    ref: o,
    onMouseDown: $(e.onMouseDown, (c) => {
      t ? f.onItemFocus(i) : c.preventDefault();
    }),
    onFocus: $(
      e.onFocus,
      () => f.onItemFocus(i)
    ),
    onKeyDown: $(e.onKeyDown, (c) => {
      if (c.key === "Tab" && c.shiftKey) {
        f.onItemShiftTab();
        return;
      }
      if (c.target !== c.currentTarget) return;
      const d = de(c, f.orientation, f.dir);
      if (d !== void 0) {
        c.preventDefault();
        let s = l().filter(
          (a) => a.focusable
        ).map(
          (a) => a.ref.current
        );
        if (d === "last") s.reverse();
        else if (d === "prev" || d === "next") {
          d === "prev" && s.reverse();
          const a = s.indexOf(c.currentTarget);
          s = f.loop ? ue(s, a + 1) : s.slice(a + 1);
        }
        setTimeout(
          () => L(s)
        );
      }
    })
  })));
}), se = {
  ArrowLeft: "prev",
  ArrowUp: "prev",
  ArrowRight: "next",
  ArrowDown: "next",
  PageUp: "first",
  Home: "first",
  PageDown: "last",
  End: "last"
};
function ae(e, o) {
  return o !== "rtl" ? e : e === "ArrowLeft" ? "ArrowRight" : e === "ArrowRight" ? "ArrowLeft" : e;
}
function de(e, o, n) {
  const t = ae(e.key, n);
  if (!(o === "vertical" && [
    "ArrowLeft",
    "ArrowRight"
  ].includes(t)) && !(o === "horizontal" && [
    "ArrowUp",
    "ArrowDown"
  ].includes(t)))
    return se[t];
}
function L(e) {
  const o = document.activeElement;
  for (const n of e)
    if (n === o || (n.focus(), document.activeElement !== o)) return;
}
function ue(e, o) {
  return e.map(
    (n, t) => e[(o + t) % e.length]
  );
}
const Te = re, Ee = fe;
export {
  ge as $,
  Te as a,
  Ee as b
};
